.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-image-holz{
    filter: brightness(95%);
}

.border-holz-gold{
    border-top: 2px solid #f8b133;
    border-bottom: 2px solid #f8b133;
}

.text-holz-gold{
  color: #f8b133;
}

#responsive-navbar-nav > .navbar-nav > .nav-link{
    color: white;
}

#responsive-navbar-nav > .navbar-nav > .nav-link.active{
    color: #f8b133;
}


/* Breadcrumbs */
.container > .row > nav > .breadcrumb{
    margin-bottom: 0px;
    background: none;
    padding: 0px;
}

.container > .row > nav > .breadcrumb >li {
    font-weight: bold;
    font-size: 110%;
}

.container > .row > nav > .breadcrumb >li > a {
    color: white;
    padding: 12px;
}

.container > .row > nav > .breadcrumb >li.active > a {
    cursor: default;
    color: #f8b133!important;
}
.container > .row > nav > .breadcrumb >li.active:hover {
    cursor: default;
    background: unset;
}
.container > .row > nav > .breadcrumb >li.active > a:hover {
    cursor: default !important;
    color: #f8b133!important;
}


.container > .row > nav > .breadcrumb > .breadcrumb-item:hover{
    margin-bottom: 0px;
    /*background: rgba(255,255,255, 0.4);*/
    /*background: rgba(248, 177, 51, 0.5);*/
    border-radius: 0px;
}


.container > .row > nav > .breadcrumb >li > a:hover {
    color: white!important;
    text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    content:none !important;
}

div > ul.footer-links > li > a:hover {
    text-decoration: none;
    color: #f8b133!important;
}

ul.footer-links > li > a.active {
    color: #f8b133!important;
}

div > a.footer-social-media:hover {
    color: #f8b133!important;
}

/* Little Anchor workaround */
.anchor{
    display: block;
    height: 115px; /*same height as header*/
    margin-top: -115px; /*same height as header*/
    visibility: hidden;
}

a.footer-contact-link{
    text-decoration: none;
    color: white;
}

a.footer-contact-link:hover{
    text-decoration: none;
    color: #f8b133!important;
}

.bg-dark-holz {
    background-color: #212529 !important;
}

nav.bg-dark-holz {
    background-color: #212529 !important;
}

a.comp-contact-link{
    text-decoration: none;
    color: #212529;
}

a.comp-contact-link:hover{
    text-decoration: none;
    color: #f8b133!important;
}




div.frontside > div.card.card-border-holz {
    border: 1px solid #212529;
    background: #ffffff;
}

div.backside > div.card.card-border-holz {
    border: 1px solid #f8b133;
    background: rgba(248,177, 51, 0.1);
}

.image-flip{
    cursor: pointer;
}

.image-flip:hover .backside,
.image-flip.hover .backside {
    -webkit-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg);
    border-radius: .25rem;
}

.image-flip:hover .frontside,
.image-flip.hover .frontside {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    width: 100%;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 327px; /*312px vorher*/
    /*312px vorher*/
}

@media (max-width: 280px) {
    .frontside .card,
    .backside .card {
        min-height: 318px; /*312px vorher*/
    }
}
@media (min-width: 320px) and (max-width: 359px) {
    .frontside .card,
    .backside .card {
        min-height: 370px; /*312px vorher*/
    }
}
@media (min-width: 360px) and (max-width: 374px) {
    .frontside .card,
    .backside .card {
        min-height: 421px; /*312px vorher*/
    }
}
@media (min-width: 375px) and (max-width: 410px) {
    .frontside .card,
    .backside .card {
        min-height: 440px; /*312px vorher*/
    }
}
@media (min-width: 411px) and (max-width: 539px) {
    .frontside .card,
    .backside .card {
        min-height: 487px; /*312px vorher*/
    }
}
@media (min-width: 540px) and (max-width: 767px) {
    .frontside .card,
    .backside .card {
        min-height: 652px; /*312px vorher*/
    }
}
@media (min-width: 768px) and (max-width: 1366px) {
    .frontside .card,
    .backside .card {
        min-height: 267px; /*312px vorher*/
        font-size: 70%;
    }
    .card-title {
        font-size: 170%;
    }
    .card-sub-title {
        font-size: 150%;
    }
}
@media (min-width: 1024px) and (max-width: 1366px) {
    .frontside .card,
    .backside .card {
        min-height: 267px; /*312px vorher*/
    }
}



/* Querformat */
@media (min-width: 640px) and (max-width: 736px) and (min-height: 280px) and (max-height: 540px){
    .frontside .card,
    .backside .card {
        min-height: 305px; /*312px vorher*/
    }
}

@media (min-width: 1366px) and (min-height: 1024px) and (max-height: 1024px){
    .frontside .card,
    .backside .card {
        min-height: 325px; /*312px vorher*/
    }
}
@media (min-width: 1024px) and (min-height: 768px) and (max-height: 768px){
    .frontside .card,
    .backside .card {
        min-height: 267px; /*312px vorher*/
    }
}
@media (min-width: 812px) and (min-height: 375px) and (max-height: 375px){
    .frontside .card,
    .backside .card {
        min-height: 267px;
        font-size: 70%/*312px vorher*/
    }
}
@media (min-width: 576px) and (max-width: 767px) and (max-height: 990px){
    .frontside .card,
    .backside .card {
        min-height: 305px; /*312px vorher*/
    }
}



.backside .card a {
    font-size: 18px;
}

.frontside .card .card-body img {
    width: 165px;
    height: 165px;
    border-radius: 50%;
}


a.holz-link-to-home {
    color: #282c34;
    text-decoration-line: none;
    font-weight: bold;
}

a.holz-link-to-home:hover {
    color: #f8b133;
    text-decoration-line: none;
}

a.holz-cookie-link {
    color: #f8b133;
}

a.holz-cookie-link:hover {
    color: #f8b133;
    text-decoration-line: none;
}

.card-img-instrument-icon {
    position: absolute;
    /*width: 12%;*/
    height: 20%;
    bottom: 7px;
    right: 7px;
}